import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { Form, Input, Button, Alert, Typography } from "antd";

const { Title, Paragraph } = Typography;

const RegisterPage = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    if (success) {
      navigate("/start");
    }
  }, [success, navigate]);

  const handleRegister = async (values) => {
    setLoading(true);

    const { name, email, password, phone } = values;

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userProfile = { displayName: name };

      const userProfilePromise = updateProfile(user, userProfile);

      const userDataPromise = setDoc(doc(db, "users", user.uid), {
        name: name,
        email: email,
        phone: phone || "",
        registrationDate: new Date().toISOString(),
        role: "user",
        isActive: false,
      });

      await Promise.all([userProfilePromise, userDataPromise]);
      setSuccess(true);
    } catch (error) {
      console.error("Fehler beim Anmelden:", error);
      if (error.code === "auth/email-already-in-use") {
        setError("Die angegebene E-Mail Adresse ist bereits vergeben.");
      } else if (error.code === "auth/invalid-email") {
        setError("Die angegebene E-Mail Adresse ist ungültig.");
      } else if (error.code === "auth/weak-password") {
        setError(
          "Das angegebene Passwort ist zu schwach. Bitte geben Sie ein Passwort mit mindestens 6 Zeichen ein."
        );
      } else {
        setError(
          "Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="w-full max-w-lg p-8 bg-white rounded-xl overflow-hidden shadow-lg">
          <Title level={2} className="text-center">
            Registrierung erfolgreich
          </Title>
          <Paragraph className="mt-4 text-center">
            Sie werden in Kürze weitergeleitet...
          </Paragraph>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-full max-w-lg p-8 bg-white rounded-xl overflow-hidden shadow-lg">
        <Title level={2} className="text-center">
          Account erstellen
        </Title>
        <Paragraph className="mt-4 text-center">
          Nachdem Sie einen Benutzeraccount erstellt haben, können Sie sich auf
          unserer Seite anmelden und einen Tennisplatz reservieren.
        </Paragraph>
        <Paragraph className="mt-2 text-center">
          Sie haben schon einen Account?{" "}
          <Link
            to="/login"
            className="ml-1 text-blue-500 font-medium text-bold hover:underline"
          >
            Jetzt anmelden
          </Link>
        </Paragraph>
        {error && (
          <Alert
            message="Fehler"
            description={error}
            type="error"
            showIcon
            className="my-4"
          />
        )}
        <Form
          onFinish={handleRegister}
          className="mt-8 space-y-6"
          layout="vertical"
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message:
                  "Bitte Namen eingeben, der mindestens 5 Zeichen lang ist",
                min: 5,
              },
            ]}
            hasFeedback
          >
            <Input
              size="large"
              type="text"
              placeholder="Vorname und Nachname"
            />
          </Form.Item>
          <Form.Item name="phone" hasFeedback>
            <Input
              size="large"
              type="text"
              placeholder="Telefonnummer (freiwillig)"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Bitte E-Mail Adresse eingeben" },
            ]}
            hasFeedback
          >
            <Input size="large" type="email" placeholder="E-Mail Adresse" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message:
                  "Bitte Passwort eingeben, das mindestens 6 Zeichen lang ist",
                min: 6,
              },
            ]}
            hasFeedback
          >
            <Input.Password size="large" placeholder="Passwort" />
          </Form.Item>
          <Form.Item
            name="passwordRepeat"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Bitte Passwort wiederholen" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Die eingegebenen Passwörter stimmen nicht überein"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password size="large" placeholder="Passwort wiederholen" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              loading={loading}
            >
              Registrieren
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default RegisterPage;
