import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Divider } from "antd";

const ChangeReservationModal = ({
  open,
  selectedReservation,
  onCancel,
  onCancelReservation,
  onChangeReservation,
}) => {
  const [reservation, setReservation] = useState({ id: "", description: "" });

  useEffect(() => {
    if (selectedReservation) {
      setReservation(selectedReservation);
    }
  }, [selectedReservation]);

  const showConfirmModal = () => {
    Modal.confirm({
      title: "Reservierung stornieren?",
      content: "Sind Sie sicher, dass Sie die Reservierung stornieren möchten?",
      okText: "Stornieren",
      okType: "danger",
      cancelText: "Abbrechen",
      onOk: () => onCancelReservation(reservation.id),
    });
  };

  return (
    <Modal
      title="Reservierung ändern"
      open={open}
      onCancel={onCancel}
      cancelText="Abbrechen"
      onOk={() => onChangeReservation(reservation.id, reservation.description)}
      okText="Speichern"
    >
      <div style={{ marginBottom: 20 }}>
        <p>
          Hier können Sie die Details Ihrer Reservierung anpassen oder die
          Reservierung bei Bedarf stornieren.
        </p>
        <Input
          value={reservation.description}
          placeholder="Beschreibung"
          onChange={(e) =>
            setReservation({ ...reservation, description: e.target.value })
          }
          className="mt-3"
          maxLength={30}
        />
        <p className="mt-1 text-sm">
          Verbleibende Zeichen: {30 - reservation.description.length}
        </p>
        <Divider plain>oder</Divider>
        <Button danger block onClick={showConfirmModal}>
          Reservierung stornieren
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeReservationModal;
