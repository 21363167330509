import React, { useState } from "react";
import { Modal, Typography, Input, Button } from "antd";
import {
  isAfter,
  isBefore,
  addMinutes,
  setHours,
  setMinutes,
  startOfDay,
  differenceInMinutes,
} from "date-fns";

const NewReservationModal = ({
  open,
  onOk,
  onCancel,
  selectedTime,
  selectedCourt,
  reservations,
  events,
}) => {
  const { Title } = Typography;
  const [description, setDescription] = useState("");
  const [selectedDuration, setSelectedDuration] = useState(null);

  const getMaxDuration = (start, court) => {
    const courtClose = setHours(setMinutes(startOfDay(start), 0), 22);
    let endTime = addMinutes(start, 240);
    if (isAfter(endTime, courtClose)) endTime = courtClose;

    const allAppointments = [...reservations, ...events];
    const nextAppointment = allAppointments.find(
      (appointment) =>
        appointment.court === court &&
        !appointment.canceled &&
        isBefore(appointment.start, endTime) &&
        isAfter(appointment.end, start)
    );

    if (nextAppointment) endTime = nextAppointment.start;

    return differenceInMinutes(endTime, start);
  };

  const renderDurationButtons = () => {
    if (!selectedTime || !selectedCourt) return null;
    const maxDuration = getMaxDuration(selectedTime, selectedCourt);
    const durations = [];

    for (let i = 30; i <= maxDuration; i += 30) {
      durations.push(i);
    }

    return durations.map((duration) => (
      <Button
        type={duration === selectedDuration ? "primary" : "default"}
        key={duration}
        onClick={() => setSelectedDuration(duration)}
      >
        {duration} Minuten
      </Button>
    ));
  };

  const handleSave = async () => {
    onOk(description, selectedDuration);
    setSelectedDuration(null);
    setDescription("");
  };
  const handleCancel = () => {
    setSelectedDuration(null);
    setDescription("");
    onCancel();
  };

  return (
    <Modal open={open} onCancel={handleCancel} footer={null}>
      <div>
        <Title level={5}>Reservierungsdauer wählen</Title>
        <div className="flex flex-wrap gap-4 justify-center">
          {renderDurationButtons()}
        </div>
        <div className="mt-4">
          <Title level={5}>Beschreibung</Title>
          <p>
            Sie können Ihrer Reservierung eine Beschreibung hinzufügen, wie zum
            Beispiel "Training Erwachsene". Diese Beschreibung wird für andere
            Benutzer sichtbar sein.
          </p>
          <Input
            value={description}
            placeholder="Beschreibung"
            onChange={(e) => setDescription(e.target.value)}
            className="mt-3"
            maxLength={30}
          />
          <p className="mt-1 text-sm">
            Verbleibende Zeichen: {30 - description.length}
          </p>
          <Button
            disabled={!selectedDuration}
            type="primary"
            className="mt-4"
            onClick={handleSave}
          >
            Reservieren
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NewReservationModal;
