import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Typography, Alert } from "antd";
import { doSendPasswordResetMail } from "../firebase/auth";

const { Title, Paragraph } = Typography;

const PasswordResetPage = () => {
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const resetPasswordHandler = (values) => {
    setError(null);
    doSendPasswordResetMail(values.email)
      .then(() => {
        setEmail("");
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(
          "Es gab einen Fehler beim Zurücksetzen des Passworts. Bitte versuchen Sie es erneut."
        );
      });
  };

  if (success) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="w-full max-w-md p-8 bg-white rounded-xl overflow-hidden shadow-lg">
          <Paragraph className="mt-4 text-center">
            Wenn die eingegebene E-Mail-Adresse in unserem System gefunden
            wurde, erhalten Sie eine E-Mail mit einem Link, um Ihr Passwort
            zurückzusetzen. Bitte überprüfen Sie auch Ihren Spam-Ordner.
          </Paragraph>
          <Link
            to="/login"
            className="block mt-3 text-center text-blue-500 font-semibold hover:underline"
          >
            Zur Login Seite
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-full max-w-md p-8 bg-white rounded-xl overflow-hidden shadow-lg">
        <Title level={2} className="text-center">
          Passwort vergessen
        </Title>
        <Paragraph className="mt-3 text-center">
          Bitte geben Sie in dem Formular die E-Mail-Adresse ein, mit der Sie
          sich angemeldet haben. Sie bekommen im Anschluss eine E-Mail mit einem
          Link, mit dem Sie Ihr Passwort zurücksetzen können.
        </Paragraph>
        {error && (
          <Alert message={error} type="error" showIcon className="my-4" />
        )}
        <Form
          name="password_reset"
          className="mt-4"
          onFinish={resetPasswordHandler}
          layout="vertical"
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Bitte E-Mail Adresse eingeben" },
            ]}
            hasFeedback
          >
            <Input
              size="large"
              type="email"
              placeholder="E-Mail-Adresse"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              disabled={!email}
            >
              Senden
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PasswordResetPage;
