import React, { useState, useEffect } from "react";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, message, Drawer, Menu, Layout, Typography, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../context/userContext";
import { doSignOut } from "../../firebase/auth";
import debounce from "lodash/debounce";

const { Header } = Layout;
const { Title } = Typography;

const SiteHeader = () => {
  const { userLoggedIn, userData } = useUser();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  const navigate = useNavigate();

  const handleResize = debounce(() => {
    setIsMobile(window.innerWidth < 1280);
  }, 300);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logout = () => {
    doSignOut();
    message.success("Sie wurden erfolgreich abgemeldet.");
    navigate("/start");
  };

  const toggleDrawer = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <Header
      className="bg-header"
      style={{ padding: "0 48px", height: "100px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Title
          level={2}
          onClick={() => navigate("/")}
          style={{ color: "white", margin: 0, cursor: "pointer" }}
        >
          Tennisplatz
        </Title>
        {userLoggedIn ? (
          <>
            {isMobile ? (
              <>
                <Button
                  type="text"
                  icon={
                    <MenuOutlined
                      style={{ color: "white", fontSize: "24px" }}
                    />
                  }
                  onClick={toggleDrawer}
                />
                <Drawer
                  title="Navigation"
                  placement="right"
                  closable={true}
                  onClose={toggleDrawer}
                  open={isNavOpen}
                  closeIcon={<CloseOutlined />}
                >
                  <Menu mode="vertical" onClick={toggleDrawer}>
                    {userData?.role === "admin" && (
                      <>
                        <Menu.Item key="0">
                          <Link to="/userlist">Benutzerliste</Link>
                        </Menu.Item>
                        <Menu.Item key="1">
                          <Link to="/eventlist">Events</Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <Link to="/reservationslist">
                            Alle Reservierungen
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    <Menu.Item key="3">
                      <Link to="/my-reservations">Meine Reservierungen</Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                      <Link to="/user-settings">Accounteinstellungen</Link>
                    </Menu.Item>
                    <Menu.Item key="5" onClick={logout}>
                      <span style={{ color: "red", cursor: "pointer" }}>
                        Logout
                      </span>
                    </Menu.Item>
                  </Menu>
                </Drawer>
              </>
            ) : (
              <Space size="large">
                {userData?.role === "admin" && (
                  <>
                    <Link
                      to="/userlist"
                      style={{ color: "white", fontSize: "18px" }}
                    >
                      Benutzerliste
                    </Link>
                    <Link
                      to="/eventlist"
                      style={{ color: "white", fontSize: "18px" }}
                    >
                      Events
                    </Link>
                    <Link
                      to="/reservationslist"
                      style={{ color: "white", fontSize: "18px" }}
                    >
                      Alle Reservierungen
                    </Link>
                  </>
                )}
                <Link
                  to="/my-reservations"
                  style={{ color: "white", fontSize: "18px" }}
                >
                  Meine Reservierungen
                </Link>
                <Link
                  to="/user-settings"
                  style={{ color: "white", fontSize: "18px" }}
                >
                  Accounteinstellungen
                </Link>
                <Link
                  to="/login"
                  onClick={logout}
                  style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}
                >
                  Logout
                </Link>
              </Space>
            )}
          </>
        ) : (
          <Link
            to="/login"
            style={{ color: "white", fontSize: "18px", fontWeight: "bold" }}
          >
            Login
          </Link>
        )}
      </div>
    </Header>
  );
};

export default SiteHeader;
