import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Layout } from "antd";

import SiteHeader from "./components/UI/Header";
import Footer from "./components/UI/Footer";
import StartPage from "./components/StartPage";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import PasswordResetPage from "./components/PasswordResetPage";
import SettingsPage from "./components/SettingsPage";
import ReservationsPage from "./components/ReservationsPage";
import ReservationsList from "./components/adm/ReservationsList";
import UserList from "./components/adm/UserList";
import NotFoundPage from "./components/NotFoundPage";
import NotAuthorizedPage from "./components/NotAuthorizedPage";

import { useUser } from "./context/userContext";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import EventList from "./components/adm/EventList";

const { Content } = Layout;

function App() {
  const { loading } = useUser();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsAuthChecked(true);
    }
  }, [loading]);

  if (!isAuthChecked) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>Seite wird geladen...</p>
      </div>
    );
  }

  return (
    <Router>
      <Layout className="min-h-screen">
        <SiteHeader />
        <Content
          style={{
            padding: "24px",
            margin: "0 auto",
            width: "100%",
            maxWidth: "1600px",
          }}
        >
          <div className="site-layout-content">
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/start" element={<StartPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/password-reset" element={<PasswordResetPage />} />
              <Route
                path="/my-reservations"
                element={
                  <PrivateRoute>
                    <ReservationsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user-settings"
                element={
                  <PrivateRoute>
                    <SettingsPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/userlist"
                element={
                  <AdminRoute>
                    <UserList />
                  </AdminRoute>
                }
              />
              <Route
                path="/reservationslist"
                element={
                  <AdminRoute>
                    <ReservationsList />
                  </AdminRoute>
                }
              />
              <Route
                path="/eventlist"
                element={
                  <AdminRoute>
                    <EventList />
                  </AdminRoute>
                }
              />
              <Route path="/" element={<Navigate to="/start" />} />
              <Route path="/not-authorized" element={<NotAuthorizedPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Content>
        <Footer />
      </Layout>
    </Router>
  );
}

export default App;
