import {
  collection,
  query,
  where,
  getFirestore,
  onSnapshot,
  updateDoc,
  doc,
  getDocs,
  addDoc,
  Timestamp,
} from "firebase/firestore";

import { endOfYear, addYears, addDays, addWeeks } from "date-fns";

const db = getFirestore();

export const listenToEvents = (queries = [], onUpdate, onError) => {
  const ref = collection(db, "events");
  const q = query(ref, ...queries);

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          ...docData,
          id: doc.id,
          start: docData.start ? docData.start.toDate() : null,
          end: docData.end ? docData.end.toDate() : null,
          repeat_end: docData.repeat_end ? docData.repeat_end.toDate() : null,
          exclusionDates: docData.exclusionDates?.map((date) => date.toDate()),
        };
      });

      onUpdate(data);
    },
    (error) => {
      if (onError) {
        onError({
          error: error,
          description: "Die Events konnten nicht geladen werden.",
        });
        console.error(error);
      }
    }
  );

  return unsubscribe;
};

export const getActiveEvents = (onUpdate, onError) => {
  return listenToEvents([where("canceled", "==", false)], onUpdate, onError);
};

export const addEvent = async (event) => {
  try {
    const overlapping = await checkIfTimeSlotIsAvailable(event);
    if (
      overlapping.hasOverlappingEvents ||
      overlapping.hasOverlappingReservations
    ) {
      return {
        ok: false,
        overlappingEvents: overlapping.overlappingEvents || [],
        overlappingReservations: overlapping.overlappingReservations || [],
      };
    }

    const newEvent = {
      ...event,
      start: Timestamp.fromDate(event.start),
      end: Timestamp.fromDate(event.end),
      exclusionDates: event.exclusionDates?.map((date) =>
        Timestamp.fromDate(date)
      ),
      repeat_end: event.repeat_end
        ? Timestamp.fromDate(event.repeat_end)
        : null,
      canceled: false,
      created_at: Timestamp.now(),
    };

    await addDoc(collection(db, "events"), newEvent);
    return { ok: true };
  } catch (error) {
    console.error("Fehler beim Verarbeiten des Events:", error);
    return { ok: false };
  }
};

export const updateEvent = async (eventID, event) => {
  if (!eventID || !event) {
    return { ok: false, message: "Erforderliche Daten fehlen." };
  }

  event.id = eventID;

  try {
    const overlapping = await checkIfTimeSlotIsAvailable(event);
    if (overlapping.hasOverlappingEvents) {
      return {
        ok: false,
        overlappingEvents: overlapping.overlappingEvents,
      };
    }
    const eventRef = doc(db, "events", eventID);
    await updateDoc(eventRef, event);
    return { ok: true };
  } catch (error) {
    console.error(error);
    return { ok: false };
  }
};

export const cancelEvent = async (eventID) => {
  try {
    const eventRef = doc(db, "events", eventID);
    await updateDoc(eventRef, { canceled: true });
    return { ok: true };
  } catch (error) {
    console.error(error);
    return { ok: false };
  }
};

export const generateVirtualEventsForStartPage = (
  event,
  viewStartDate,
  viewEndDate
) => {
  const virtualEvents = [];

  let eventStartDate = event.start;
  let eventEndDate = event.end;
  let repeatEnd = event.repeat_end
    ? event.repeat_end
    : endOfYear(addYears(new Date(), 3));

  const isDateExcluded = (date) => {
    return event.exclusionDates.some(
      (exclusionDate) => exclusionDate.toDateString() === date.toDateString()
    );
  };

  while (eventStartDate < repeatEnd && eventStartDate < viewEndDate) {
    if (
      eventStartDate >= viewStartDate &&
      eventEndDate <= viewEndDate &&
      !isDateExcluded(eventStartDate)
    ) {
      virtualEvents.push({
        ...event,
        start: eventStartDate,
        end: eventEndDate,
      });
    }

    switch (event.repeat) {
      case "daily":
        eventStartDate = addDays(eventStartDate, 1);
        eventEndDate = addDays(eventEndDate, 1);
        break;
      case "weekly":
        eventStartDate = addWeeks(eventStartDate, 1);
        eventEndDate = addWeeks(eventEndDate, 1);
        break;
      case "all2weeks":
        eventStartDate = addWeeks(eventStartDate, 2);
        eventEndDate = addWeeks(eventEndDate, 2);
        break;
      case "all4weeks":
        eventStartDate = addWeeks(eventStartDate, 4);
        eventEndDate = addWeeks(eventEndDate, 4);
        break;
      default:
        break;
    }
  }

  return virtualEvents;
};

export const generateVirtualEventsForOverlappingCheck = (event) => {
  const virtualEvents = [];
  let startDate = event.start;
  let endDate = event.end;
  const repeatEnd = event.repeat_end
    ? event.repeat_end
    : endOfYear(addYears(new Date(), 3));

  while (endDate < repeatEnd) {
    const virtualEvent = {
      ...event,
      start: startDate,
      end: endDate,
    };

    virtualEvents.push(virtualEvent);

    switch (event.repeat) {
      case "daily":
        startDate = addDays(startDate, 1);
        endDate = addDays(endDate, 1);
        break;
      case "weekly":
        startDate = addWeeks(startDate, 1);
        endDate = addWeeks(endDate, 1);
        break;
      case "all2weeks":
        startDate = addWeeks(startDate, 2);
        endDate = addWeeks(endDate, 2);
        break;
      case "all4weeks":
        startDate = addWeeks(startDate, 4);
        endDate = addWeeks(endDate, 4);
        break;
      default:
        break;
    }

    if (virtualEvents.length > 1000) {
      break;
    }
  }

  return virtualEvents;
};

const checkIfTimeSlotIsAvailable = async (event) => {
  let overlappingEvents = [];
  let overlappingReservations = [];
  let existingVirtualEvents = [];
  let newVirtualEvents = [];

  const eventsQuery = query(
    collection(db, "events"),
    where("canceled", "==", false)
  );

  const reservationsQuery = query(
    collection(db, "reservations"),
    where("canceled", "==", false),
    where("end", ">=", event.start)
  );

  const eventsSnapshot = await getDocs(eventsQuery);
  const reservationsSnapshot = await getDocs(reservationsQuery);

  eventsSnapshot.forEach((doc) => {
    const docData = doc.data();
    const existingEvent = {
      ...docData,
      id: doc.id,
      start: docData.start ? docData.start.toDate() : null,
      end: docData.end ? docData.end.toDate() : null,
      exclusionDates: docData.exclusionDates?.map((date) => date.toDate()),
      repeat_end: docData.repeat_end ? docData.repeat_end.toDate() : null,
    };

    const existingEventsToAdd =
      existingEvent.repeat !== "norepeat"
        ? generateVirtualEventsForOverlappingCheck(existingEvent, doc.id)
        : [
            {
              ...existingEvent,
            },
          ];
    existingVirtualEvents = existingVirtualEvents.concat(existingEventsToAdd);
  });

  const newVirtualEventsToAdd =
    event.repeat !== "norepeat"
      ? generateVirtualEventsForOverlappingCheck(event)
      : [{ ...event }];

  newVirtualEvents = newVirtualEvents.concat(newVirtualEventsToAdd);

  reservationsSnapshot.docs.forEach((doc) => {
    const reservation = {
      ...doc.data(),
      id: doc.id,
      start: doc.data().start ? doc.data().start.toDate() : null,
      end: doc.data().end ? doc.data().end.toDate() : null,
    };

    const events = [...existingVirtualEvents, ...newVirtualEvents];

    events.forEach((event) => {
      if (
        reservation.start < event.end &&
        reservation.end > event.start &&
        event.court.includes(reservation.court) &&
        !isDateExcluded(
          event.exclusionDates,
          reservation.start,
          reservation.end
        )
      ) {
        overlappingReservations.push(reservation);
      }
    });
  });

  existingVirtualEvents.forEach((existingEvent) => {
    newVirtualEvents.forEach((newEvent) => {
      if (
        !isDateExcluded(
          newEvent.exclusionDates,
          existingEvent.start,
          existingEvent.end
        ) &&
        !isDateExcluded(
          existingEvent.exclusionDates,
          newEvent.start,
          newEvent.end
        ) &&
        checkOverlap(existingEvent, newEvent) &&
        existingEvent.id !== newEvent.id
      ) {
        overlappingEvents.push(existingEvent);
      }
    });
  });

  return {
    hasOverlappingReservations: overlappingReservations.length > 0,
    overlappingReservations: overlappingReservations,
    hasOverlappingEvents: overlappingEvents.length > 0,
    overlappingEvents: overlappingEvents,
  };
};

const isDateExcluded = (exclusionDates, startDate, endDate) => {
  const startDateOnly = new Date(startDate);
  startDateOnly.setHours(0, 0, 0, 0);
  const endDateOnly = new Date(endDate);
  endDateOnly.setHours(0, 0, 0, 0);

  const response = exclusionDates.some((exclusionDate) => {
    const exclusionDateOnlyDay = new Date(exclusionDate);
    exclusionDateOnlyDay.setHours(0, 0, 0, 0);
    return (
      exclusionDateOnlyDay.getTime() >= startDateOnly.getTime() &&
      exclusionDateOnlyDay.getTime() <= endDateOnly.getTime()
    );
  });
  return response;
};

function checkOverlap(event1, event2) {
  const start1 = event1.start;
  const end1 = event1.end;
  const start2 = event2.start;
  const end2 = event2.end;
  return (
    start1 < end2 &&
    end1 > start2 &&
    event1.court.some((court) => event2.court.includes(court))
  );
}
