import React from "react";
import { Modal, Button } from "antd";

const NotLoggedInModal = ({ open, onCancel, onOk }) => {
  return (
    <Modal
      title="Fehler"
      open={open}
      onCancel={oncancel}
      footer={[
        <Button danger key="cancel" onClick={onCancel}>
          Abbrechen
        </Button>,
        <Button key="ok" type="primary" onClick={onOk}>
          Zur Login Seite
        </Button>,
      ]}
    >
      <p>
        Um einen Platz zu reservieren, müssen Sie sich einloggen. Wenn Sie noch
        keine Zugangsdaten haben, können Sie sich registrieren. Ihre
        Registrierung wird im Anschluss geprüft und freigegeben.
      </p>
    </Modal>
  );
};

export default NotLoggedInModal;
