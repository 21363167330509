import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

import { notification, Row, Col, Alert } from "antd";
import {
  addMinutes,
  isBefore,
  subMinutes,
  eachDayOfInterval,
  startOfDay,
  addDays,
  isWithinInterval,
} from "date-fns";

import { useUser } from "../context/userContext";
import {
  getActiveReservations,
  addReservation,
  updateReservation,
} from "../utils/resHelper";
import {
  getActiveEvents,
  generateVirtualEventsForStartPage,
} from "../utils/eventHelper";
import ReservationTable from "./ReservationTable";
import DatePicker from "./UI/DatePicker";
import NewReservationModal from "./modals/NewReservationModal";
import ChangeReservationModal from "./modals/ChangeReservationModal";
import NotLoggedInModal from "./modals/NotLoggedInModal";

const StartPage = () => {
  const [events, setEvents] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [reservation, setReservation] = useState({ id: "", description: "" });
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [showNewReservationModal, setshowNewReservationModal] = useState(false);
  const [showChangeReservationModal, setShowChangeReservationModal] =
    useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { userLoggedIn, currentUser, userData } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeEvents = getActiveEvents(
      (data) => {
        setEvents(data);
      },
      (error) => {
        notification.error({
          message: "Fehler",
          description: error.description || "Unbekannter Fehler",
        });
        console.error("Fehler beim Laden der Events:", error);
      }
    );

    const unsubscribeReservations = getActiveReservations(
      (data) => {
        setReservations(data);
      },
      (error) => {
        notification.error({
          message: "Fehler",
          description: error.description || "Unbekannter Fehler",
        });
      }
    );

    return () => {
      unsubscribeReservations();
      unsubscribeEvents();
    };
  }, []);

  const virtualEvents = useMemo(() => {
    let virtualEventsArray = [];
    const currentDate = selectedDate || new Date();
    const minViewDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0
    );
    const maxViewDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 6,
      23,
      59,
      59
    );

    events.forEach((event) => {
      if (event.repeat === "norepeat") {
        const eventIsWithinView =
          isWithinInterval(event.start, {
            start: minViewDate,
            end: maxViewDate,
          }) ||
          isWithinInterval(event.end, {
            start: minViewDate,
            end: maxViewDate,
          });
        if (eventIsWithinView) {
          virtualEventsArray.push({
            ...event,
            start: event.start,
            end: event.end,
          });
        }
      } else {
        const generatedVirtualEvents = generateVirtualEventsForStartPage(
          event,
          minViewDate,
          maxViewDate
        );
        virtualEventsArray = virtualEventsArray.concat(generatedVirtualEvents);
      }
    });

    return virtualEventsArray.flatMap((event) =>
      event.court.length > 1
        ? event.court.map((court) => ({
            ...event,
            court,
          }))
        : [event]
    );
  }, [events, selectedDate]);

  const handleReservation = useCallback(
    (startDate, court) => {
      if (!userLoggedIn) {
        setShowErrorModal(true);
        return;
      }
      if (userLoggedIn && userData && !userData.isActive) {
        notification.error({
          message: "Fehler",
          description: "Ihr Konto ist noch nicht freigeschaltet worden.",
        });
        return;
      }

      const now = new Date();
      const thirtyMinutesAgo = subMinutes(now, 30);

      if (isBefore(startDate, thirtyMinutesAgo)) {
        notification.error({
          message: "Fehler",
          description:
            "Die Reservierung kann nicht in der Vergangenheit stattfinden.",
        });
        return;
      }

      setSelectedTime(startDate);
      setSelectedCourt(court);
      setshowNewReservationModal(true);
    },
    [userLoggedIn, userData]
  );

  const handleReservationClick = (id, description, username) => {
    if (username !== userData.name) {
      return;
    }

    setReservation({
      id,
      description,
    });
    setShowChangeReservationModal(true);
  };

  const handleNewReservation = async (description, selectedDuration) => {
    if (!selectedTime || !selectedCourt || !selectedDuration) return;

    const newReservation = {
      start: selectedTime,
      end: addMinutes(selectedTime, selectedDuration),
      court: selectedCourt,
      userID: currentUser.uid,
      userName: userData.name,
      description: description,
    };

    const res = await addReservation(newReservation);

    if (res.ok) {
      notification.success({
        message: "Reserviert",
        description: "Ihre Reservierung wurde erfolgreich erstellt.",
      });
      setshowNewReservationModal(false);
    } else {
      notification.error({
        message: "Fehler",
        description: res.description,
      });
    }
  };

  const handleCancelReservation = async (resID) => {
    const res = await updateReservation(resID, { canceled: true });

    if (res.ok) {
      notification.success({
        message: "Storniert",
        description: "Ihre Reservierung wurde erfolgreich storniert.",
      });

      setShowChangeReservationModal(false);
    } else {
      notification.error({
        message: "Fehler",
        description:
          "Fehler beim Stornieren der Reservierung. Bitte versuchen Sie es erneut.",
      });
    }
  };

  const handleChangeReservation = async (resID, description) => {
    const res = await updateReservation(resID, { description: description });

    if (res.ok) {
      notification.success({
        message: "Beschreibung geändert",
        description: "Die Beschreibung wurde geändert.",
      });
      setShowChangeReservationModal(false);
    } else {
      notification.error({
        message: "Fehler",
        description:
          "Fehler beim Abbrechen der Reservierung. Bitte versuchen Sie es erneut.",
      });
    }
  };

  const next7Days = eachDayOfInterval({
    start: startOfDay(selectedDate),
    end: startOfDay(addDays(selectedDate, 6)),
  });

  return (
    <>
      {userLoggedIn && userData && !userData.isActive && (
        <Alert
          description={
            <p className="text-center">
              Ihr Account wird gerade überprüft und in Kürze von einem
              Mitarbeiter freigeschaltet. Bis dahin können keine Reservierungen
              getätigt werden.
            </p>
          }
          className="mb-6"
          type="info"
          closable
          showIcon
        />
      )}
      <div className="mb-6">
        <h1 className="text-3xl uppercase text-center">
          Willkommen{userData?.name ? `, ${userData.name}` : ""}
        </h1>

        <p className="text-center mt-2">
          Hier sehen Sie die Übersicht der aktuellen Platzreservierungen. Um
          einen Platz zu reservieren,
          {userLoggedIn ? (
            "klicken Sie auf einen freien Slot"
          ) : (
            <Link to="/login">müssen Sie sich zuerst einloggen.</Link>
          )}
          .
        </p>
      </div>

      <DatePicker onChangeDate={setSelectedDate} />

      <Row gutter={[16, 16]}>
        {next7Days.map((date) => (
          <Col xs={24} key={date}>
            <div>
              <ReservationTable
                date={date}
                events={virtualEvents}
                reservations={reservations}
                handleReservation={handleReservation}
                handleReservationClick={handleReservationClick}
              />
            </div>
          </Col>
        ))}
      </Row>
      {showNewReservationModal && (
        <NewReservationModal
          open={showNewReservationModal}
          reservations={reservations}
          events={virtualEvents}
          selectedTime={selectedTime}
          selectedCourt={selectedCourt}
          onOk={(description, duration) =>
            handleNewReservation(description, duration)
          }
          onCancel={() => setshowNewReservationModal(false)}
        />
      )}

      {showChangeReservationModal && (
        <ChangeReservationModal
          open={showChangeReservationModal}
          selectedReservation={reservation}
          onCancel={() => setShowChangeReservationModal(false)}
          onChangeReservation={(resID, description) =>
            handleChangeReservation(resID, description)
          }
          onCancelReservation={(resID) => handleCancelReservation(resID)}
        />
      )}

      {showErrorModal && (
        <NotLoggedInModal
          open={showErrorModal}
          onCancel={() => setShowErrorModal(false)}
          onOk={() => navigate("/login")}
        />
      )}
    </>
  );
};

export default StartPage;
