import { useState, useEffect } from "react";
import {
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { useUser } from "../context/userContext";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Space,
  Col,
  notification,
} from "antd";
import { MailOutlined, LockOutlined, PhoneOutlined } from "@ant-design/icons";
import { updateUserEmail } from "../utils/helper";

const { Title } = Typography;

const SettingsPage = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [passwordRepeatError, setPasswordRepeatError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  const { currentUser, userData, updateUserData } = useUser();

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email || "");
    }

    if (userData) {
      setPhone(userData.phone || "");
    }
  }, [currentUser, userData]);

  const reset = () => {
    setEmail(currentUser.email || "");
    setPhone(userData.phone || "");
    setCurrentPassword("");
    setNewPassword("");
    setPasswordRepeat("");
    setEmailError(false);
    setCurrentPasswordError(false);
    setNewPasswordError(false);
    setPasswordRepeatError(false);
    setFormTouched(false);
  };

  const handleInputChange = (inputFieldChange) => (e) => {
    inputFieldChange(e.target.value);
    setFormTouched(true);
  };

  const handleSave = async () => {
    let hasErrors = false;
    setEmailError(false);
    setCurrentPasswordError(false);
    setNewPasswordError(false);
    setPasswordRepeatError(false);

    if (email === "") {
      setEmailError(true);
      hasErrors = true;
    }

    if (newPassword && newPassword.length < 6) {
      setNewPasswordError(true);
      hasErrors = true;
    }

    if (newPassword !== "" && newPassword !== passwordRepeat) {
      setPasswordRepeatError(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    setIsSaving(true);

    const updatedData = {};

    if (email !== currentUser.email) {
      updatedData.email = email;
      await updateUserEmail(currentUser.uid, email);
    }

    if (phone && phone !== userData.phone) {
      updatedData.phone = phone;
    }

    try {
      if (Object.keys(updatedData).length > 0) {
        const res = await updateUserData(currentUser.uid, updatedData);
        if (res.ok) {
          notification.success({
            message: "Erfolg",
            description: "Ihre Daten wurden erfolgreich aktualisiert!",
          });
        } else {
          throw new Error(res.message);
        }
      }
    } catch (error) {
      notification.error({
        message: "Fehler",
        description:
          error.message ||
          "Ihre Daten konnten nicht aktualisiert werden. Bitte versuchen Sie es erneut.",
      });
    } finally {
      setIsSaving(false);
    }

    if (newPassword) {
      try {
        const userCredential = EmailAuthProvider.credential(
          currentUser.email,
          currentPassword
        );
        await reauthenticateWithCredential(currentUser, userCredential);
        await updatePassword(currentUser, newPassword);
        notification.success({
          message: "Erfolg",
          description: "Ihr Passwort wurde erfolgreich geändert!",
        });
      } catch (error) {
        setCurrentPasswordError(true);
        notification.error({
          message: "Fehler",
          description:
            "Passwortaktualisierung fehlgeschlagen. Überprüfen Sie Ihr aktuelles Passwort und versuchen Sie es erneut.",
        });
        return;
      }
    }

    reset();
  };

  return (
    <>
      <Title level={2} className="mb-6">
        Accounteinstellungen
      </Title>

      <Form layout="vertical" onFinish={handleSave}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Title level={4}>E-Mail & Telefonnummer</Title>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie eine E-Mail-Adresse ein.",
                },
              ]}
              validateStatus={emailError ? "error" : ""}
              help={emailError && "Bitte geben Sie eine E-Mail-Adresse ein."}
            >
              <Input
                prefix={
                  <MailOutlined
                    style={{
                      fontSize: "16px",
                      marginRight: "8px",
                      color: "#8c8c8c",
                    }}
                  />
                }
                placeholder="E-Mail Adresse"
                value={email}
                onChange={handleInputChange(setEmail)}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Input
                prefix={
                  <PhoneOutlined
                    style={{
                      fontSize: "16px",
                      marginRight: "8px",
                      color: "#8c8c8c",
                    }}
                  />
                }
                placeholder="Telefonnummer"
                value={phone}
                onChange={handleInputChange(setPhone)}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Title level={4}>Passwort ändern</Title>
            <Form.Item
              validateStatus={currentPasswordError ? "error" : ""}
              help={
                currentPasswordError &&
                "Das eingegebene Passwort ist ungültig. Bitte versuchen Sie es erneut."
              }
            >
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{
                      fontSize: "16px",
                      marginRight: "8px",
                      color: "#8c8c8c",
                    }}
                  />
                }
                placeholder="Altes Passwort"
                value={currentPassword}
                onChange={handleInputChange(setCurrentPassword)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              validateStatus={newPasswordError ? "error" : ""}
              help={
                newPasswordError &&
                "Das Passwort ist zu kurz. Bitte versuchen Sie es erneut."
              }
            >
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{
                      fontSize: "16px",
                      marginRight: "8px",
                      color: "#8c8c8c",
                    }}
                  />
                }
                placeholder="Neues Passwort"
                value={newPassword}
                onChange={handleInputChange(setNewPassword)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              validateStatus={passwordRepeatError ? "error" : ""}
              help={
                passwordRepeatError &&
                "Die eingegebenen Passwörter stimmen nicht überein."
              }
            >
              <Input.Password
                prefix={
                  <LockOutlined
                    style={{
                      fontSize: "16px",
                      marginRight: "8px",
                      color: "#8c8c8c",
                    }}
                  />
                }
                placeholder="Passwort wiederholen"
                value={passwordRepeat}
                onChange={handleInputChange(setPasswordRepeat)}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Space size="middle" className="mt-4">
          <Button
            type="primary"
            htmlType="submit"
            loading={isSaving}
            disabled={!formTouched || isSaving}
          >
            Speichern
          </Button>
          <Button onClick={reset}>Zurücksetzen</Button>
        </Space>
      </Form>
    </>
  );
};

export default SettingsPage;
