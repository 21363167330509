import { getFirestore, collection, getDocs } from "firebase/firestore";

import { getAuth } from "firebase/auth";
const db = getFirestore();

export const getAllUsers = async () => {
  try {
    const usersRef = collection(db, "users");
    const usersSnapshot = await getDocs(usersRef);
    const users = usersSnapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
    return { ok: true, users };
  } catch (error) {
    console.error(error);
    return { ok: false, message: "Fehler beim Abrufen der Benutzerdaten." };
  }
};
export const verifyUserEmail = async (uid) => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      throw new Error("Fehler bei der Übergabe der CurrentUser ID");
    }

    const token = await user.getIdToken(true);

    const response = await fetch(
      "https://us-central1-tennisplaner-24282.cloudfunctions.net/verifyUserEmail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ uid }),
      }
    );

    if (response.ok) {
      return { ok: true };
    }

    if (!response.ok) {
      throw new Error("Fehler.");
    }
  } catch (error) {
    console.error("Leider ist etwas schief gelaufen:", error);
    throw error;
  }
};

export const updateUserEmail = async (uid, newEmail) => {
  const auth = getAuth();
  const idToken = await auth.currentUser.getIdToken(true);

  const response = await fetch(
    "https://us-central1-tennisplaner-24282.cloudfunctions.net/updateUserEmail",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ uid, newEmail }),
    }
  );

  if (response.ok) {
    return { ok: true };
  }

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Fehler: ${errorData.message}`);
  }

  await response.json();
};

export const updateUserAccountStatus = async (uid, status) => {
  const auth = getAuth();
  const idToken = await auth.currentUser.getIdToken(true);

  const response = await fetch(
    "https://us-central1-tennisplaner-24282.cloudfunctions.net/updateUserAccountStatus",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({ uid, status }),
    }
  );

  if (response.ok) {
    return { ok: true };
  }

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Fehler: ${errorData.message}`);
  }

  await response.json();
};
