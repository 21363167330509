import React, { useState } from "react";
import { Link } from "react-router-dom";
import { doSignInWithEmailAndPassword } from "../firebase/auth";
import { useNavigate } from "react-router-dom";
import { Input, Button, Form, Alert, Typography } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

function LoginPage() {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [error, setError] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const loginHandler = async (values) => {
    const { email, password } = values;
    if (!email || !password) {
      setError("Bitte alle Felder ausfüllen");
      return;
    }
    setIsSigningIn(true);
    setError(null);
    try {
      await doSignInWithEmailAndPassword(email, password);
      navigate("/start");
    } catch (error) {
      console.error("Fehler beim Anmelden:", error);

      // Abfangen spezifischer Fehlermeldungen
      switch (error.code) {
        case "auth/user-disabled":
          setError("Das Benutzerkonto wurde deaktiviert.");
          break;
        case "auth/invalid-credential":
          setError("Die Anmeldedaten sind ungültig.");
          break;
        default:
          setError(
            "Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten."
          );
      }

      form.resetFields();
    } finally {
      setIsSigningIn(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-full max-w-md p-8 bg-white rounded-xl overflow-hidden shadow-lg">
        <Title level={2} className="text-center">
          Anmelden
        </Title>
        {error && (
          <Alert
            message="Fehler"
            description={error}
            type="error"
            showIcon
            className="my-4"
          />
        )}
        <Form
          form={form}
          onFinish={loginHandler}
          className="mt-8 space-y-6"
          layout="vertical"
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Bitte E-Mail Adresse eingeben" },
            ]}
            hasFeedback
          >
            <Input
              size="large"
              type="email"
              placeholder="E-Mail Adresse"
              prefix={
                <MailOutlined
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                    color: "#8c8c8c",
                  }}
                />
              }
              className="rounded-md"
              style={{ color: "#595959" }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Bitte Passwort eingeben" }]}
            hasFeedback
          >
            <Input.Password
              size="large"
              placeholder="Passwort"
              prefix={
                <LockOutlined
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                    color: "#8c8c8c",
                  }}
                />
              }
              className="rounded-md"
              style={{ color: "#595959" }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              loading={isSigningIn}
            >
              Anmelden
            </Button>
          </Form.Item>
          <Link
            to="/password-reset"
            className="block text-center md:text-right text-blue-500 text-sm font-medium hover:underline"
          >
            Passwort vergessen?
          </Link>
        </Form>
        <div className="flex items-center justify-between my-4">
          <hr className="w-full border-t border-gray-300" />
          <span className="mx-4 text-gray-500">oder</span>
          <hr className="w-full border-t border-gray-300" />
        </div>
        <Paragraph className="text-center text-sm text-gray-600">
          Noch keinen Zugang?{" "}
          <Link
            to="/register"
            className="text-blue-500 font-medium text-bold hover:underline"
          >
            Jetzt registrieren
          </Link>
        </Paragraph>
      </div>
    </div>
  );
}

export default LoginPage;
