import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  notification,
} from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import { setMinutes, setHours, getHours, getMinutes, format } from "date-fns";
import { updateReservation } from "../../utils/resHelper";
import { addEvent, cancelEvent, updateEvent } from "../../utils/eventHelper";
import dayjs from "dayjs";
import "dayjs/locale/de";

const { Option } = Select;

const EventModal = ({ open, onCancel, selectedEvent, closeModal }) => {
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [isRecurringEvent, setisRecurringEvent] = useState(true);
  const [event, setEvent] = useState({ repeat: "norepeat" });
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedEvent) {
      const exclusionDates =
        selectedEvent.exclusionDates?.map((date) => dayjs(date)) ?? [];

      const formattedEvent = {
        ...selectedEvent,
        startDate: dayjs(selectedEvent.start),
        startTime: dayjs(selectedEvent.start),
        endDate: dayjs(selectedEvent.end),
        endTime: dayjs(selectedEvent.end),
        repeatEndDate: selectedEvent.repeat_end
          ? dayjs(selectedEvent.repeat_end)
          : null,
        exclusionDates: exclusionDates,
      };
      setEvent(formattedEvent);
      form.setFieldsValue(formattedEvent);
      setisRecurringEvent(selectedEvent.repeat === "norepeat");
    } else {
      setEvent({ repeat: "norepeat" });
      form.resetFields();
      setisRecurringEvent(true);
    }
  }, [selectedEvent, form]);

  const formatEventForDB = (values) => {
    const startDate = values.startDate.toDate();
    const endDate = values.endDate ? values.endDate.toDate() : startDate;

    const startTime = values.startTime.toDate();
    const endTime = values.endTime ? values.endTime.toDate() : startTime;

    const start = setMinutes(
      setHours(startDate, getHours(startTime)),
      getMinutes(startTime)
    );
    const end = setMinutes(
      setHours(endDate, getHours(endTime)),
      getMinutes(endTime)
    );

    const exclusionDates =
      values.exclusionDates?.map((dateString) => new Date(dateString)) || [];

    return {
      start: start,
      end: end,
      repeat: values.repeat,
      repeat_end: values.repeatEndDate ? values.repeatEndDate.toDate() : null,
      description: values.description,
      court: values.court.sort((a, b) => parseInt(a) - parseInt(b)),
      exclusionDates: exclusionDates,
    };
  };

  const handleEventUpdate = async (event) => {
    try {
      const res = await updateEvent(selectedEvent.id, event);

      if (res.overlappingEvents) {
        const overlappingRes = await handleOverlapping(
          res.overlappingEvents,
          event
        );

        if (!overlappingRes) {
          return false;
        }

        const updateRes = await updateEvent(event.id, event);
        if (!updateRes.ok) {
          return handleUpdateError();
        }
      }

      closeModal();
      form.resetFields();
      notification.success({
        message: "Das Event wurde erfolgreich aktualisiert.",
      });
      return true;
    } catch (error) {
      return handleUpdateError(error);
    }
  };

  const handleNewEvent = async (newEvent) => {
    try {
      const res = await addEvent(newEvent);

      if (res.overlappingEvents || res.overlappingReservations) {
        const overlappingRes = await handleOverlapping(
          res.overlappingEvents || [],
          res.overlappingReservations || [],
          newEvent
        );
        if (!overlappingRes) {
          return false;
        }

        const updateRes = await addEvent(newEvent);
        if (!updateRes.ok) {
          return handleUpdateError();
        }
      }

      closeModal();
      form.resetFields();
      notification.success({
        message: "Das Event wurde erfolgreich erstellt.",
      });
      return true;
    } catch (error) {
      return handleUpdateError(error);
    }
  };

  const handleOverlapping = async (
    overlappingEvents,
    overlappingReservations,
    event
  ) => {
    const formatTime = (time) => format(time, "dd.MM.yy HH:mm");

    if (event.repeat === "norepeat" || overlappingEvents.length < 5) {
      return new Promise((resolve) => {
        Modal.confirm({
          title: "Überschneidende Veranstaltungen",
          okText: "Stornieren",
          okButtonProps: { danger: true },
          cancelText: "Abbrechen",
          content: (
            <div>
              <p>
                <>
                  Es gibt eine Terminüberschneidung
                  {overlappingEvents.length > 0 && (
                    <>
                      {` mit `}
                      {overlappingEvents.length === 1
                        ? "einem"
                        : overlappingEvents.length}{" "}
                      Event
                      {overlappingEvents.length > 1 ? "s" : ""}
                    </>
                  )}
                  {overlappingEvents.length > 0 &&
                    overlappingReservations.length > 0 &&
                    " und "}
                  {overlappingReservations.length > 0 && (
                    <>
                      {` mit `}
                      {overlappingReservations.length === 1
                        ? "einer "
                        : overlappingReservations.length}{" "}
                      Reservierung
                      {overlappingReservations.length > 1 ? "en" : ""}
                    </>
                  )}
                  .
                </>
              </p>

              {overlappingEvents.map((event, index) => (
                <div key={index}>
                  <h3 className="text-md font-bold mt-2 mb-1">
                    Eventbeschreibung: {event.description}
                  </h3>
                  <p>Start: {formatTime(event.start)}</p>
                  <p>Ende: {formatTime(event.end)}</p>
                  <p>
                    Platz:{" "}
                    {event.court
                      .sort((a, b) => parseInt(a) - parseInt(b))
                      .join(", ")}
                  </p>
                  {event.repeat !== "norepeat" && (
                    <p>
                      Wiederholung:
                      {event.repeat === "daily"
                        ? " Täglich"
                        : event.repeat === "weekly"
                        ? " Wöchentlich"
                        : event.repeat === "all2weeks"
                        ? " Alle 2 Wochen"
                        : " Alle 4 Wochen"}
                    </p>
                  )}
                </div>
              ))}

              <div className="mt-3">
                {overlappingReservations.map((reservation, index) => (
                  <div key={index}>
                    <h3 className="text-md font-bold mt-2 mb-1">
                      Reservierung von: {reservation.userName}
                    </h3>
                    <p>Start: {formatTime(reservation.start)}</p>
                    <p>Ende: {formatTime(reservation.end)}</p>
                    <p>Platz: {reservation.court}</p>
                  </div>
                ))}
              </div>

              <p className="mt-3">
                Mit einem Klick auf "Stornieren" werden alle oben aufgelisteten
                Termine storniert und das neue Event angelegt.
              </p>
              <p className="mt-1">
                Bei wiederkehrenden Events wird das Event nur für diesen Tag
                storniert.
              </p>
            </div>
          ),
          onOk: async () => {
            const cancelEventsResult = await handleEventCancellation(
              overlappingEvents,
              event
            );
            const cancelReservationsResult =
              await handleReservationCancellation(overlappingReservations);
            Modal.destroyAll();
            if (cancelEventsResult && cancelReservationsResult) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
          onCancel: () => {
            Modal.destroyAll();
            resolve(false);
          },
        });
      });
    } else {
      Modal.error({
        title: "Überschneidende Veranstaltungen",
        content: (
          <div>
            <p>
              Das Event kann nicht erstellt werden. Es überschneidet sich mit{" "}
              {overlappingEvents.length} Events. Bitte prüfen Sie zuerst die
              Angaben oder die anderen Events bevor Sie fortfahren.
            </p>
          </div>
        ),
      });
    }
  };

  const handleReservationCancellation = async (overlappingReservations) => {
    console.log(overlappingReservations);
    const cancellationResults = await Promise.all(
      overlappingReservations.map(async (overlappingReservation) => {
        return updateReservation(overlappingReservation.id, {
          canceled: true,
        });
      })
    );
    const failedCancellations = cancellationResults.filter(
      (result) => !result.ok
    );
    if (failedCancellations.length > 0) {
      notification.error({
        message: "Fehler beim Stornieren",
        description:
          "Ein oder mehrere Reservierungen konnten nicht storniert werden. Bitte versuchen Sie es erneut.",
      });
      return false;
    }
    return true;
  };

  const handleEventCancellation = async (overlappingEvents, event) => {
    const cancellationResults = await Promise.all(
      overlappingEvents.map(async (overlappingEvent) => {
        if (overlappingEvent.repeat === "norepeat") {
          return cancelEvent(overlappingEvent.id);
        } else {
          const conflictDate = findConflictDate(event, overlappingEvent);
          overlappingEvent.exclusionDates.push(conflictDate);
          return updateEvent(overlappingEvent.id, overlappingEvent);
        }
      })
    );

    const failedCancellations = cancellationResults.filter(
      (result) => !result.ok
    );

    if (failedCancellations.length > 0) {
      notification.error({
        message: "Fehler beim Stornieren",
        description:
          "Ein oder mehrere Events konnten nicht storniert werden. Bitte versuchen Sie es erneut.",
      });
      return false;
    }
    return true;
  };

  const handleUpdateError = (error) => {
    notification.error({
      message: "Fehler beim Aktualisieren",
      description: error
        ? `Ein unerwarteter Fehler ist aufgetreten: ${error.message}`
        : "Die Veranstaltung konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.",
    });
    return false;
  };

  const handleFormFinish = async () => {
    try {
      const values = await form.validateFields();
      const event = formatEventForDB(values);
      selectedEvent ? handleEventUpdate(event) : handleNewEvent(event);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFormHasChanges(false);
    onCancel();
  };

  const findConflictDate = (newEvent, overlappingEvent) => {
    const newEventStart = new Date(newEvent.start);
    const newEventEnd = new Date(newEvent.end);
    const overlappingEventStart = new Date(overlappingEvent.start);
    const overlappingEventEnd = new Date(overlappingEvent.end);

    newEventStart.setHours(0, 0, 0, 0);
    newEventEnd.setHours(0, 0, 0, 0);
    overlappingEventStart.setHours(0, 0, 0, 0);
    overlappingEventEnd.setHours(0, 0, 0, 0);

    let date = new Date(newEventStart);
    while (date <= newEventEnd) {
      if (date >= overlappingEventStart && date <= overlappingEventEnd) {
        return new Date(date);
      }
      date.setDate(date.getDate() + 1);
    }

    return null;
  };

  return (
    <Modal
      title={selectedEvent ? "Event bearbeiten" : "Neues Event erstellen"}
      open={open}
      onOk={handleFormFinish}
      onCancel={handleCancel}
      okText="Speichern"
      okButtonProps={{ disabled: !formHasChanges }}
      cancelText="Abbrechen"
    >
      <Form
        key={event.id || "new"}
        className="mt-3"
        form={form}
        onFinish={handleFormFinish}
        layout="vertical"
        initialValues={event}
        onValuesChange={(changedValues, allValues) => {
          setFormHasChanges(true);
          if ("repeat" in changedValues) {
            setisRecurringEvent(changedValues.repeat === "norepeat");
          }
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="description"
              label="Eventbeschreibung"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie eine Beschreibung ein.",
                },
              ]}
            >
              <Input placeholder="bspw. Kindertraining" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="court"
              label="Platz"
              rules={[
                {
                  required: true,
                  message: "Bitte wählen Sie mindestens einen Platz aus.",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Wählen Sie die Plätze aus"
                allowClear
              >
                <Option value="1">Platz 1</Option>
                <Option value="2">Platz 2</Option>
                <Option value="3">Platz 3</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Startdatum"
              name="startDate"
              rules={[
                { required: true, message: "Bitte wählen Sie ein Startdatum." },
              ]}
            >
              <DatePicker
                locale={locale}
                format="DD.MM.YYYY"
                className="w-full"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Enddatum"
              name="endDate"
              tooltip="Wird nur bei mehrtägigen Events benötigt."
            >
              <DatePicker
                locale={locale}
                format="DD.MM.YYYY"
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Startzeit"
              name="startTime"
              rules={[
                { required: true, message: "Bitte wählen Sie eine Startzeit." },
              ]}
            >
              <TimePicker
                locale={locale}
                minuteStep={30}
                format="HH:mm"
                className="w-full"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Endzeit"
              name="endTime"
              rules={[
                { required: true, message: "Bitte wählen Sie eine Endzeit." },
              ]}
            >
              <TimePicker
                locale={locale}
                minuteStep={30}
                format="HH:mm"
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="repeat"
              label="Wiederholung"
              rules={[
                {
                  required: true,
                  message: "Bitte wählen Sie eine Wiederholungsoption.",
                },
              ]}
            >
              <Select
                placeholder="Wählen Sie die Wiederholung aus"
                onChange={(value) => {
                  setisRecurringEvent(value === "norepeat");
                }}
              >
                <Option value="norepeat">Keine Wiederholung</Option>
                <Option value="daily">Täglich</Option>
                <Option value="weekly">Wöchentlich</Option>
                <Option value="all2weeks">Alle 2 Wochen</Option>
                <Option value="all4weeks">Alle 4 Wochen</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="repeatEndDate"
              label="Wiederholungsende"
              rules={[{ required: false }]}
            >
              <DatePicker
                locale={locale}
                format="DD.MM.YYYY"
                disabled={isRecurringEvent}
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="exclusionDates"
              label="Außer am"
              tooltip="An diesen Tagen findet das Event nicht statt."
            >
              <DatePicker
                multiple
                maxTagCount="responsive"
                format="DD.MM.YYYY"
                locale={locale}
                disabled={isRecurringEvent}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EventModal;
