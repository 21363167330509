import React, { useEffect } from "react";
import { Modal, Form, Input } from "antd";

const ChangeUserDataModal = ({ open, onCancel, onOk, userData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    if (userData) {
      form.setFieldsValue(userData);
    }
  }, [userData, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Fehler:", info);
      });
  };

  return (
    <Modal
      title="Benutzerdaten ändern"
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Speichern"
      cancelText="Abbrechen"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Bitte geben Sie einen Namen ein." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie eine E-Mail-Adresse ein.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Telefonnummer" name="phone">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeUserDataModal;
