import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Typography, Modal, notification } from "antd";
import { EditOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { format } from "date-fns";
import { getActiveEvents, cancelEvent } from "../../utils/eventHelper";
import EventModal from "../modals/EventModal";
dayjs.locale("de");
const EventList = () => {
  const [events, setEvents] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const { Title } = Typography;

  useEffect(() => {
    const unsubscribeEvents = getActiveEvents(
      (data) => {
        setEvents(data);
      },
      (error) => {
        notification.error({
          message: "Fehler",
          description: error.description || "Unbekannter Fehler",
        });
      }
    );

    return () => unsubscribeEvents();
  }, []);

  const handleEditEvent = (event) => {
    setSelectedEvent(event);
    setShowEventModal(true);
  };

  const handleCancelEvent = (id) => {
    Modal.confirm({
      title: "Event stornieren?",
      content: "Sind Sie sicher, dass Sie dieses Event stornieren möchten?",
      okText: "Stornieren",
      okType: "danger",
      cancelText: "Abbrechen",
      onOk: async () => {
        const res = await cancelEvent(id);
        if (res.ok) {
          notification.success({
            message: "Event storniert",
            description: "Das Event wurde erfolgreich storniert.",
          });
        } else {
          notification.error({
            message: "Fehler",
            description:
              "Das Event konnte nicht storniert werden. Bitte versuchen Sie es erneut.",
          });
        }
      },
    });
  };

  const onCloseEventModal = () => {
    setShowEventModal(false);
    setSelectedEvent(null);
  };

  const filteredOneTimeEvents = events.filter((event) => {
    return !event.canceled && event.repeat === "norepeat";
  });

  const filteredRepeatEvents = events.filter((event) => {
    return !event.canceled && event.repeat !== "norepeat";
  });

  return (
    <div>
      <Row justify={"end"} align={"center"}>
        <Col>
          <Button onClick={() => setShowEventModal(true)} type="primary">
            Neues Event
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={4}>Einmalige Events</Title>
          <Table
            dataSource={filteredOneTimeEvents}
            rowKey="id"
            locale={{ emptyText: "Keine einmaligen Events gefunden" }}
          >
            <Column
              title="Zeitraum"
              key="start"
              render={(_, record) => {
                const start = format(record.start, "dd.MM.yy HH:mm");
                const end = format(record.end, "dd.MM.yy HH:mm");
                return `${start} - ${end}`;
              }}
            />
            <Column
              title="Platz"
              dataIndex="court"
              key="court"
              render={(_, record) => {
                const courtOutput = Array.isArray(record.court)
                  ? record.court.join(", ")
                  : record.court;
                return courtOutput;
              }}
            />
            <Column
              title="Beschreibung"
              dataIndex="description"
              key="description"
            />
            <Column
              title="Aktionen"
              key="actions"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    icon={<EditOutlined style={{ color: "#1890ff" }} />}
                    onClick={() => handleEditEvent(record)}
                    style={{ marginRight: 8, border: 0 }}
                  />
                  <Button
                    icon={<CloseCircleOutlined />}
                    onClick={() => handleCancelEvent(record.id)}
                    danger
                    style={{ border: 0 }}
                  />
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>
      <Row className="mt-8">
        <Col span={24}>
          <Title level={4}>Wiederkehrende Events</Title>
          <Table
            dataSource={filteredRepeatEvents}
            rowKey="id"
            locale={{ emptyText: "Keine wiederkehrenden Events gefunden" }}
          >
            <Column
              title="Wann"
              key="repeat"
              render={(_, record) => {
                const day = dayjs(record.start).format("dddd");
                let repeatText = record.repeat;
                switch (record.repeat) {
                  case "daily":
                    repeatText = "Täglich";
                    break;
                  case "weekly":
                    repeatText = `Jeden ${day}`;
                    break;
                  case "all2weeks":
                    repeatText = `Jeden 2. ${day}`;
                    break;
                  case "all4weeks":
                    repeatText = `Jeden 4. ${day}`;
                    break;
                  default:
                    repeatText = "Unregelmäßig";
                }
                return repeatText;
              }}
            />
            <Column
              title="Von"
              dataIndex="start"
              key="start"
              render={(_, record) => dayjs(record.start).format("HH:mm")}
            />
            <Column
              title="Bis"
              dataIndex="end"
              key="end"
              render={(_, record) => dayjs(record.end).format("HH:mm")}
            />
            <Column
              title="Platz"
              dataIndex="court"
              key="court"
              render={(_, record) => {
                const courtOutput = Array.isArray(record.court)
                  ? record.court.join(", ")
                  : record.court;
                return courtOutput;
              }}
            />
            <Column
              title="Beschreibung"
              dataIndex="description"
              key="description"
            />
            <Column
              title="Läuft bis"
              dataIndex="repeat_end"
              key="repeat_end"
              render={(text) =>
                text ? dayjs(text).format("DD.MM.YY") : "Offen"
              }
            />
            <Column
              title="Aktionen"
              key="actions"
              render={(_, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    icon={<EditOutlined style={{ color: "#1890ff" }} />}
                    onClick={() => handleEditEvent(record)}
                    style={{ marginRight: 8, border: 0 }}
                  />
                  <Button
                    icon={<CloseCircleOutlined />}
                    onClick={() => handleCancelEvent(record.id)}
                    danger
                    style={{ border: 0 }}
                  />
                </div>
              )}
            />
          </Table>
        </Col>
      </Row>

      {showEventModal && (
        <EventModal
          open={showEventModal}
          onCancel={onCloseEventModal}
          closeModal={onCloseEventModal}
          selectedEvent={selectedEvent}
        />
      )}
    </div>
  );
};

export default EventList;
