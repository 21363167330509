import { Result, Button } from "antd";
import { Link } from "react-router-dom";

const NotAuthorizedPage = () => {
  return (
    <Result
      status="403"
      title="Nicht autorisiert"
      subTitle="Sie sind nicht berechtigt auf diese Seite zuzugreifen."
      extra={
        <Button type="primary">
          <Link to="/">Zurück zur Startseite</Link>
        </Button>
      }
    />
  );
};

export default NotAuthorizedPage;
