import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Typography,
  Row,
  Col,
  Space,
  Input,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import Column from "antd/es/table/Column";
import { CloseOutlined } from "@ant-design/icons";
import { getAllReservations, updateReservation } from "../../utils/resHelper";

const formatDate = (date) =>
  new Date(date).toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

const formatTime = (date) =>
  new Date(date).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

const ReservationsList = () => {
  const [reservations, setReservations] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const { Title } = Typography;

  useEffect(() => {
    const unsubscribe = getAllReservations(
      (data) => {
        const formattedReservations = data.map((reservation) => {
          return {
            ...reservation,
            date: formatDate(reservation.start),
            startTime: formatTime(reservation.start),
            endTime: formatTime(reservation.end),
          };
        });
        setReservations(formattedReservations);
      },
      (error) => {
        message.error(error.description);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleCancelReservation = async (id) => {
    const res = await updateReservation(id, { canceled: true });
    if (res.ok) {
      message.success("Reservierung erfolgreich storniert");
    } else {
      message.error(res.description);
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  const filteredUserReservations = reservations
    .filter(
      (reservation) =>
        !filteredInfo.canceled || reservation.court === filteredInfo.court
    )
    .filter(
      (reservation) =>
        reservation.userName &&
        reservation.userName.toLowerCase().includes(searchText.toLowerCase())
    );

  return (
    <>
      <Row className="mb-4" justify="space-between" align="middle">
        <Col>
          <Title level={3}>Benutzerreservierungen</Title>
        </Col>
        <Col>
          <Space size="middle">
            <Input
              placeholder="Suchen"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button type="primary" onClick={clearFilters}>
              Alle Filter zurücksetzen
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={filteredUserReservations}
        rowKey="id"
        onChange={handleChange}
        filteredInfo={filteredInfo}
        sortedInfo={sortedInfo}
        scroll={{ x: "max-content" }}
      >
        <Column
          title="Datum"
          dataIndex="start"
          key="date"
          sorter={(a, b) => new Date(a.start) - new Date(b.start)}
          defaultSortOrder="descend"
          render={(start) =>
            new Date(start).toLocaleDateString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          }
        />
        <Column title="Start" dataIndex="startTime" key="startTime" />
        <Column title="Ende" dataIndex="endTime" key="endTime" />
        <Column
          title="Platz"
          dataIndex="court"
          key="court"
          filters={[
            { text: "Platz 1", value: "1" },
            { text: "Platz 2", value: "2" },
            { text: "Platz 3", value: "3" },
          ]}
          onFilter={(value, record) => record.court.toString() === value}
          filterMultiple={false}
          filteredValue={filteredInfo.court || null}
        />
        <Column
          title="Beschreibung"
          dataIndex="description"
          key="description"
        />
        <Column title="Reserviert von" dataIndex="userName" key="username" />
        <Column
          ellipsis={true}
          title="Status"
          dataIndex="canceled"
          key="canceled"
          filters={[
            { text: "Nur offene", value: false },
            { text: "Nur stornierte", value: true },
          ]}
          onFilter={(value, record) => record.canceled === value}
          filterMultiple={false}
          filteredValue={filteredInfo.canceled || null}
          render={(canceled, record) => {
            if (canceled) {
              return "Storniert";
            } else {
              return record.end > new Date() ? "Offen" : "Abgelaufen";
            }
          }}
        />
        <Column
          title="Aktionen"
          key="actions"
          render={(record) => {
            const now = new Date();
            const isPastEndTime = new Date(record.end) <= now;

            return !record.canceled && !isPastEndTime ? (
              <Popconfirm
                title="Möchten Sie diese Reservierung wirklich stornieren?"
                onConfirm={() => handleCancelReservation(record.id)}
                okText="Ja"
                cancelText="Nein"
              >
                <Tooltip title="Stornieren">
                  <Button
                    type="link"
                    icon={<CloseOutlined />}
                    style={{ color: "red" }}
                  />
                </Tooltip>
              </Popconfirm>
            ) : null;
          }}
        />
      </Table>
    </>
  );
};

export default ReservationsList;
