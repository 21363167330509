import { useState, useEffect } from "react";
import {
  Button,
  Table,
  Tag,
  Typography,
  Space,
  Modal,
  Form,
  Input,
  notification,
  Col,
  Row,
} from "antd";
import Column from "antd/es/table/Column";
import {
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import {
  verifyUserEmail,
  updateUserEmail,
  updateUserAccountStatus,
  getAllUsers,
} from "../../utils/helper";

import { cancelReservationsByUseriD } from "../../utils/resHelper";

import ChangeUserDataModal from "../modals/ChangeUserDataModal";
import { useUser } from "../../context/userContext";

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState({});
  const [showUserdataModal, setShowUserdataModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { Title } = Typography;
  const { confirm } = Modal;

  const { updateUserData } = useUser();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await getAllUsers();
        if (res.ok) {
          const users = res.users
            .filter((user) => !user.disabled)
            .sort(
              (a, b) =>
                new Date(b.registrationDate) - new Date(a.registrationDate)
            );
          setUserList(users);
        } else {
          throw new Error(
            res.message || "Unbekannter Fehler beim Laden der Benutzer"
          );
        }
      } catch (error) {
        notification.error({
          message: "Fehler",
          description:
            error.message || "Ein unbekannter Fehler ist aufgetreten",
        });
      }
    };

    fetchUsers();
  }, []);

  const handleActivate = (id) => {
    confirm({
      title: "Aktivierung",
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
      content: "Sind Sie sicher, dass Sie diesen Benutzer aktivieren möchten?",
      cancelText: "Abbrechen",
      okText: "Aktivieren",
      centered: true,
      async onOk() {
        await updateUserData(id, { isActive: true });
        await verifyUserEmail(id);

        setUserList((prevUserList) =>
          prevUserList.map((user) =>
            user.id === id ? { ...user, isActive: true } : user
          )
        );
        notification.success({
          message: "User aktiviert",
          description: "Der User wurde erfolgreich aktiviert.",
        });
      },
    });
  };

  const handleEdit = async (id) => {
    let user = userList.find((user) => user.id === id);
    setUserData(user);
    setShowUserdataModal(true);
  };

  const changeUserdata = async (userInput) => {
    let isUpdated = false;
    let updatedUserData = { ...userData };

    if (userInput.name !== userData.name) {
      updatedUserData.name = userInput.name;
      isUpdated = true;
    }
    if (userInput.email !== userData.email) {
      updatedUserData.email = userInput.email;
      await updateUserEmail(userData.id, userInput.email);
      isUpdated = true;
    }
    if (userInput.phone !== userData.phone) {
      updatedUserData.phone = userInput.phone;
      isUpdated = true;
    }

    setUserData(updatedUserData);

    if (!isUpdated) {
      return;
    }

    const res = await updateUserData(userData.id, updatedUserData);
    if (res.ok) {
      setShowUserdataModal(false);
      setUserList((prevUserList) =>
        prevUserList.map((user) =>
          user.id === userData.id ? { ...updatedUserData } : user
        )
      );
      notification.success({
        message: "User aktualisiert",
        description: "Der User wurde erfolgreich aktualisiert.",
      });
    } else {
      notification.error({
        message: "Fehler",
        description: res.message,
      });
    }
  };

  const handleDelete = async (id) => {
    confirm({
      title: "User löschen?",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content:
        "Sind Sie sicher, dass Sie diesen User löschen möchten? Alle offenen Reservierungen dieses Benutzers werden storniert.",
      cancelText: "Abbrechen",
      okText: "Löschen",
      okButtonProps: { style: { background: "red" } },
      centered: true,
      async onOk() {
        try {
          const userDataResult = await updateUserData(id, { disabled: true });
          const userAccountStatusResult = await updateUserAccountStatus(
            id,
            true
          );
          const cancelReservationsResult = await cancelReservationsByUseriD(id);

          if (
            userDataResult.ok &&
            userAccountStatusResult.ok &&
            cancelReservationsResult.ok
          ) {
            setUserList((prevUserList) =>
              prevUserList.filter((user) => user.id !== id)
            );
            notification.success({
              message: "User gelöscht",
              description: "Der User wurde erfolgreich gelöscht.",
            });
          } else {
            throw new Error();
          }
        } catch (error) {
          console.log(error);
          notification.error({
            message: "Fehler",
            description:
              "Ein Fehler ist aufgetreten. Der User konnte nicht gelöscht werden: ",
          });
        }
      },
    });
  };

  const filteredUserList = userList.filter((user) =>
    user.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>Benutzerliste</Title>
        </Col>
        <Col>
          <Space size="middle">
            <Input
              placeholder="Suchen"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button type="primary">Alle Filter zurücksetzen</Button>
          </Space>
        </Col>
      </Row>

      <Table
        scroll={{ x: "max-content" }}
        className="mt-8"
        dataSource={filteredUserList}
        rowKey="id"
      >
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          sorter={(a, b) => a.name.localeCompare(b.name)}
        />
        <Column title="Email" dataIndex="email" key="email" />
        <Column title="Telefonnummer" dataIndex="phone" key="phone" />
        <Column
          title="Status"
          dataIndex="isActive"
          key="isActive"
          filters={[
            { text: "Aktiv", value: true },
            { text: "Nicht aktiv", value: false },
          ]}
          onFilter={(value, record) => record.isActive === value}
          render={(isActive, record) => (
            <Tag
              onClick={isActive ? null : () => handleActivate(record.id)}
              color={isActive ? "green" : "red"}
              style={{ cursor: isActive ? "default" : "pointer" }}
            >
              {isActive ? "Aktiviert" : "Nicht aktiv"}
            </Tag>
          )}
        />
        <Column
          title="Actions"
          key="actions"
          render={(_, record) => (
            <Space size="middle">
              <EditOutlined
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => handleEdit(record.id)}
              />
              <DeleteOutlined
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleDelete(record.id)}
              />
            </Space>
          )}
        />
      </Table>
      <Modal
        title="Benutzerdaten ändern"
        open={showUserdataModal}
        onOk={() => changeUserdata()}
        onCancel={() => setShowUserdataModal(false)}
        okText="Speichern"
        cancelText="Abbrechen"
      >
        <Form layout="vertical" initialValues={userData}>
          <Form.Item label="Name" name="name">
            <Input
              onChange={(e) =>
                setUserData({ ...userData, name: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Telefonnummer" name="phone">
            <Input
              onChange={(e) =>
                setUserData({ ...userData, phone: e.target.value })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <ChangeUserDataModal
        open={showUserdataModal}
        onCancel={() => setShowUserdataModal(false)}
        onOk={(userInput) => changeUserdata(userInput)}
        userData={userData}
      />
    </>
  );
};

export default UserList;
