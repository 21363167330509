import { Button, Result } from "antd";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Result
      status="404"
      title="Fehler 404 - Seite nicht gefunden"
      subTitle="Die aufgerufene Seite konnte nicht gefunden werden."
      extra={
        <Button type="primary">
          <Link to="/">Zurück zur Startseite</Link>
        </Button>
      }
    />
  );
};

export default NotFoundPage;
