import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const AppFooter = () => (
  <Footer className="bg-footer text-white text-center">
    &copy; {new Date().getFullYear()} Issho eG
  </Footer>
);

export default AppFooter;
