import { createContext, useState, useEffect, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

const UserContext = createContext();
const db = getFirestore();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return () => unsubscribe();
  }, []);

  const initializeUser = async (user) => {
    if (user) {
      setCurrentUser(user);
      setUserLoggedIn(true);
      await fetchUserData(user);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
      setUserData(null);
      setLoading(false);
    }
  };

  const fetchUserData = async (user) => {
    const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      setUserData(userDoc.data());
    } else {
      setUserData(null);
    }
    setLoading(false);
  };

  const updateUserData = async (userID, updatedData) => {
    if (!userID || !updatedData) {
      return {
        ok: false,
        message: "Keine UserID oder aktualisierte Daten angegeben.",
      };
    }

    try {
      const userRef = doc(db, "users", userID);
      await updateDoc(userRef, updatedData);
      setUserData((prevData) => ({ ...prevData, ...updatedData }));
      return { ok: true };
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Benutzerdaten:", error);
      return {
        ok: false,
        message:
          "Fehler beim Aktualisieren der Benutzerdaten. Bitte versuchen Sie es später erneut.",
      };
    }
  };

  const value = {
    currentUser,
    userLoggedIn,
    loading,
    userData,
    updateUserData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
