import React, { useState, useEffect } from "react";
import { DatePicker as AntDatePicker } from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
dayjs.extend(weekday);
dayjs.extend(localeData);

const DatePicker = ({ onChangeDate }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const today = dayjs();
  const maxDate = dayjs().endOf("year").add("1", "year");

  useEffect(() => {
    onChangeDate(selectedDate.toDate());
  }, [selectedDate, onChangeDate]);

  return (
    <div className="flex justify-center mt-5 mb-5">
      <div className="flex items-center border rounded-lg shadow-sm">
        <button
          onClick={() => {
            const newDate = selectedDate.subtract(7, "days");
            if (newDate.isAfter(today)) {
              setSelectedDate(newDate);
            } else {
              setSelectedDate(today);
            }
          }}
          className="px-4 py-2 border-r text-sm bg-gray-100 hover:bg-gray-200 focus:outline-none"
        >
          -7 Tage
        </button>

        <AntDatePicker
          locale={locale}
          value={selectedDate}
          onChange={(date) => {
            if (!date) {
              return setSelectedDate(dayjs(new Date()));
            } else {
              setSelectedDate(dayjs(date));
            }
          }}
          placeholder="Datum auswählen"
          format="DD.MM.YYYY"
          minDate={today}
          maxDate={maxDate}
          className="border-0 bg-gray-100 hover:bg-gray-200"
        />

        <button
          onClick={() => {
            const newDate = selectedDate.add(7, "days");
            if (newDate.isBefore(maxDate)) {
              setSelectedDate(newDate);
            } else {
              setSelectedDate(maxDate);
            }
          }}
          className="px-4 py-2 border-l text-sm bg-gray-100 hover:bg-gray-200 focus:outline-none"
        >
          +7 Tage
        </button>
      </div>
    </div>
  );
};

export default DatePicker;
