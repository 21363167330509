// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFjMwnP_K839ruPBw6XcLlBXA52giBY_w",
  authDomain: "tennisplaner-24282.firebaseapp.com",
  projectId: "tennisplaner-24282",
  storageBucket: "tennisplaner-24282.appspot.com",
  messagingSenderId: "261209396354",
  appId: "1:261209396354:web:b555727dda220cd6a7509b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, db, auth };
