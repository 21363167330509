import React from "react";
import { Table, Typography } from "antd";
import {
  format,
  isWithinInterval,
  setHours,
  setMinutes,
  startOfDay,
} from "date-fns";
import { de } from "date-fns/locale";
import "./ReservationTable.css";
import { useUser } from "../context/userContext";

const ReservationTable = ({
  date,
  reservations,
  events,
  handleReservation,
  handleReservationClick,
}) => {
  const { userLoggedIn } = useUser();
  const { Title } = Typography;

  const timeSlots = [];
  for (let hour = 8; hour <= 22; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      if (hour === 22 && minute === 0) {
        timeSlots.push(setHours(setMinutes(startOfDay(date), minute), hour));
        break;
      }
      timeSlots.push(setHours(setMinutes(startOfDay(date), minute), hour));
    }
  }

  const displayTimeSlots = timeSlots.filter((slot) => {
    const hours = slot.getHours();
    const minutes = slot.getMinutes();
    return hours < 22 || (hours === 21 && minutes === 30);
  });

  const getSlotIndices = (start, end) => {
    const startSlot = format(start, "HH:mm");
    const endSlot = format(end, "HH:mm");
    const startSlotIndex = timeSlots.findIndex(
      (ts) => format(ts, "HH:mm") === startSlot
    );
    const endSlotIndex =
      timeSlots.findIndex((ts) => format(ts, "HH:mm") === endSlot) - 1;

    return { startSlotIndex, endSlotIndex };
  };

  const columns = [
    {
      dataIndex: "court",
      key: "court",
      render: (text) => <strong>{`Platz ${text}`}</strong>,
    },
    ...displayTimeSlots.map((slot, index) => ({
      title: format(slot, "HH:mm"),
      dataIndex: `slot_${index}`,
      key: `slot_${index}`,
      render: (_, record) => {
        for (const reservation of reservations) {
          if (
            reservation.court === record.court &&
            !reservation.canceled &&
            format(reservation.start, "yyyy-MM-dd") ===
              format(date, "yyyy-MM-dd") &&
            isWithinInterval(slot, {
              start: reservation.start,
              end: reservation.end,
            })
          ) {
            const { startSlotIndex, endSlotIndex } = getSlotIndices(
              reservation.start,
              reservation.end
            );
            if (index === startSlotIndex) {
              return {
                children: (
                  <div
                    key={reservation.id}
                    onClick={() =>
                      handleReservationClick(
                        reservation.id,
                        reservation.description,
                        reservation.userName
                      )
                    }
                    className="table-cell-content cursor-pointer bg-red"
                  >
                    {userLoggedIn
                      ? `${reservation.userName}${
                          reservation.description
                            ? ` (${reservation.description})`
                            : ""
                        }`
                      : "Belegt"}
                  </div>
                ),
                props: {
                  colSpan: endSlotIndex - startSlotIndex + 1,
                },
              };
            } else if (index > startSlotIndex && index <= endSlotIndex) {
              return { props: { colSpan: 0 } };
            }
          }
        }

        for (const event of events) {
          if (
            event.court.includes(record.court) &&
            !event.canceled &&
            format(event.start, "yyyy-MM-dd") === format(date, "yyyy-MM-dd") &&
            isWithinInterval(slot, {
              start: event.start,
              end: event.end,
            })
          ) {
            const { startSlotIndex, endSlotIndex } = getSlotIndices(
              event.start,
              event.end
            );
            if (index === startSlotIndex) {
              return {
                children: (
                  <div
                    key={event.id}
                    className="table-cell-content bg-blue-400"
                  >
                    {event.description}
                  </div>
                ),
                props: {
                  colSpan: endSlotIndex - startSlotIndex + 1,
                },
              };
            } else if (index > startSlotIndex && index <= endSlotIndex) {
              return { props: { colSpan: 0 } };
            }
          }
        }

        return {
          children: (
            <div
              onClick={() => handleReservation(slot, record.court)}
              className="table-cell-content cursor-pointer bg-green"
            >
              Frei
            </div>
          ),
          props: {},
        };
      },
    })),
  ];

  const data = ["1", "2", "3"].map((court) => ({
    key: court,
    court,
    ...Object.fromEntries(
      timeSlots.map((slot, index) => [`slot_${index}`, slot])
    ),
  }));

  return (
    <>
      <Title level={3} className="text-center mb-3 font-bold">
        Reservierungen für {format(date, "EEEE, dd.MM.yyyy", { locale: de })}
      </Title>
      <div
        className="table-container"
        style={{ maxWidth: "100%", overflowX: "auto" }}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          rowClassName={() => "editable-row"}
        />
      </div>
    </>
  );
};

export default ReservationTable;
