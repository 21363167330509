import {
  collection,
  query,
  where,
  getFirestore,
  onSnapshot,
  addDoc,
  Timestamp,
  updateDoc,
  doc,
  getDocs,
  writeBatch,
} from "firebase/firestore";

const db = getFirestore();

export const listenToReservations = (queries = [], onUpdate, onError) => {
  const ref = collection(db, "reservations");
  const q = query(ref, ...queries);

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          ...docData,
          id: doc.id,
          start: docData.start ? new Date(docData.start.seconds * 1000) : null,
          end: docData.end ? new Date(docData.end.seconds * 1000) : null,
          reservationTime: docData.reservationTime
            ? new Date(docData.reservationTime.seconds * 1000)
            : null,
        };
      });

      onUpdate(data);
    },
    (error) => {
      if (onError) {
        onError({
          error: error,
          description: "Die Reservierungen konnten nicht geladen werden.",
        });
        console.error("Fehler beim Laden der Reservierungen:", error);
      }
    }
  );

  return unsubscribe;
};

export const getAllReservations = (onUpdate, onError) => {
  return listenToReservations([], onUpdate, onError);
};

export const getActiveReservations = (onUpdate, onError) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return listenToReservations(
    [where("canceled", "==", false), where("end", ">=", today)],
    onUpdate,
    onError
  );
};

export const getUserReservations = (userID, onUpdate, onError) => {
  if (!userID) return null;
  return listenToReservations(
    [where("userID", "==", userID)],
    onUpdate,
    onError
  );
};

export const addReservation = async (reservation) => {
  if (!reservation)
    return {
      ok: false,
      description: "Die Reservierung wurde nicht korrekt übermittelt.",
    };

  const newReservation = {
    ...reservation,
    start: Timestamp.fromDate(reservation.start),
    end: Timestamp.fromDate(reservation.end),
    reservationTime: Timestamp.now(),
    canceled: false,
  };
  try {
    await addDoc(collection(db, "reservations"), newReservation);
    return { ok: true };
  } catch (error) {
    return {
      ok: false,
      description: "Reservierung konnte nicht erstellt werden.",
      error,
    };
  }
};

export const updateReservation = async (resID, updates) => {
  try {
    const reservationRef = doc(db, "reservations", resID);
    await updateDoc(reservationRef, updates);
    return { ok: true };
  } catch (error) {
    return {
      ok: false,
      error,
    };
  }
};

export const cancelReservationsByUseriD = async (id) => {
  const reservationsRef = collection(db, "reservations");
  const q = query(reservationsRef, where("userID", "==", id));
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    return { ok: true };
  }

  try {
    const batch = writeBatch(db);
    snapshot.forEach((doc) => {
      const reservationRef = doc.ref;
      batch.update(reservationRef, { canceled: true });
    });

    await batch.commit();
  } catch (error) {
    console.error(error);
    return { ok: false };
  }
};
