import { Navigate } from "react-router-dom";
import { useUser } from "../context/userContext";

const AdminRoute = ({ children }) => {
  const { userLoggedIn, userData } = useUser();
  return userLoggedIn && userData.role === "admin" ? (
    children
  ) : (
    <Navigate to="/not-authorized" />
  );
};

export default AdminRoute;
