import React, { useState, useEffect } from "react";
import { useUser } from "../context/userContext";
import { Table, Button, message, Typography, Row, Popconfirm } from "antd";
import { getUserReservations, updateReservation } from "../utils/resHelper";

const { Column } = Table;

const ReservationsPage = () => {
  const [reservations, setReservations] = useState([]);
  const { currentUser } = useUser();
  const { Title } = Typography;

  useEffect(() => {
    const unsubscribe = getUserReservations(
      currentUser.uid,
      (data) => {
        setReservations(data);
      },
      (error) => {
        message.error(error.description);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  const cancelReservation = async (id) => {
    const res = await updateReservation(id, { canceled: true });

    if (res.ok) {
      message.success("Reservierung erfolgreich storniert");
    } else {
      message.error(res.description);
    }
  };

  return (
    <>
      <Row className="mb-4">
        <Title level={3}>Meine Reservierungen</Title>
      </Row>

      {reservations.length === 0 ? (
        <p>Für diesen Account wurden keine Reservierungen gefunden.</p>
      ) : (
        <Table
          scroll={{ x: "max-content" }}
          dataSource={reservations}
          rowKey="id"
        >
          <Column
            title="Zeitraum"
            dataIndex="start"
            key="start"
            sorter={(a, b) => new Date(a.start) - new Date(b.start)}
            defaultSortOrder="descend"
            render={(start, record) =>
              `${start.toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
                hour: "numeric",
                minute: "numeric",
              })} - ${record.end.toLocaleTimeString("de-DE", {
                hour: "numeric",
                minute: "numeric",
              })}`
            }
          />
          <Column title="Platznummer" dataIndex="court" key="court" />
          <Column
            title="Beschreibung"
            dataIndex="description"
            key="description"
          />
          <Column
            title="Status"
            key="status"
            render={(record) =>
              record.canceled ? (
                <Button size="small" type="text" disabled>
                  Storniert
                </Button>
              ) : new Date() > new Date(record.end) ? (
                <Button size="small" type="text" disabled>
                  Abgelaufen
                </Button>
              ) : (
                <Popconfirm
                  title="Reservierung wirklich stornieren?"
                  onConfirm={() => cancelReservation(record.id)}
                  okText="Ja"
                  cancelText="Nein"
                >
                  <Button size="small" type="text" danger>
                    Stornieren
                  </Button>
                </Popconfirm>
              )
            }
          />
        </Table>
      )}
    </>
  );
};

export default ReservationsPage;
